const React = require('react');
const ContextProviders = require('src/hooks/ContextProviders').default;

exports.onClientEntry = () => {
  // IE 11 no longer supported, Safari works well with IntersectionObserver (caniuse.com)
  // Use this handler for Browser execution scripts
};

exports.wrapPageElement = ({ element, props }) => {
  return <ContextProviders>{element}</ContextProviders>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state && prevLocation) {
    location.state.referrer = prevLocation.pathname;
  }
};
