import React, { createContext, useCallback, useContext, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'src/functions/localStorageHandler';

interface FormResultControllerContextData {
  isEmailSubscriptionSubmitted: () => boolean;
  setFormSubmission: () => void;
}

const FormResultController = createContext<FormResultControllerContextData>({} as FormResultControllerContextData);

const FormResultProvider: React.FC<any> = ({ children }) => {
  const [submitted, setSubmitted] = useState(false);

  const isEmailSubscriptionSubmitted = useCallback(() => {
    if (getLocalStorage('formAlreadySubmitted') === 'true') {
      return true;
    }

    return submitted;
  }, [submitted]);

  const setFormSubmission = () => {
    setSubmitted(true);
    setLocalStorage('formAlreadySubmitted', 'true');
  };

  return (
    <FormResultController.Provider
      value={{
        isEmailSubscriptionSubmitted,
        setFormSubmission,
      }}
    >
      {children}
    </FormResultController.Provider>
  );
};

const useFormResultController = (): FormResultControllerContextData => {
  const context = useContext(FormResultController);

  if (!context) {
    throw new Error('FormResultController must be used within a FormResultProvider');
  }

  return context;
};

export { useFormResultController, FormResultProvider };
